import htmlParser from "Utils/HtmlParser"
import React, { useEffect } from "react"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import getUuid from "uuid-by-string"
import CookieService from "Utils/CookieService"
import { defaultLanguageUrl, urlLanguageLookup } from "Utils/Language"
import ShareButton from "Components/ShareButton"
import NinjaForm from "Components/NinjaForm"

const generateUuid = () => {
  const maxRandomNumber = 10000000
  const random = Math.floor(Math.random() * maxRandomNumber) + 1

  return getUuid(random.toString()).replace(/[^0-9a-z]/gi, "")
}

// Used for production build checks when runtime variables are not defined
const isBrowser = typeof window !== "undefined"

const BFLogo = "/BF_Logo.png"
const BMLogo = "/BM_Logo.png"
const genericShareImage = "/Share_Image.jpg"

// Wait for library to initialize on window
const waitForInit = (prop, callback) => {
  if (!isBrowser || !prop || !callback) return

  const checkRate = 100

  if (typeof window[prop] !== "undefined") {
    callback(window[prop])
  } else {
    setTimeout(() => waitForInit(prop, callback), checkRate)
  }
}

const getPathname = url => {
  const pageLocation = new URL(url)
  const { url: languageUrl } = urlLanguageLookup(pageLocation)
  const { pathname } = pageLocation

  return languageUrl === defaultLanguageUrl
    ? `${languageUrl}${pathname}`
    : pathname
}

const loadAssets = (htmlString, assetsContainerId) => {
  if (!isBrowser) return

  const documentFragment = document
    .createRange()
    .createContextualFragment(htmlString)

  const el = document.getElementById(assetsContainerId)

  if (el) {
    el.innerHTML = ""
    const scripts = [...documentFragment.childNodes].filter(
      fragment => fragment.nodeName !== "#text",
    )

    // eslint-disable-next-line array-callback-return
    scripts.map(node => {
      // eslint-disable-next-line no-param-reassign
      node.async = false
      // eslint-disable-next-line no-param-reassign
      if (node.src) node.onload = () => dispatchEvent(new Event("load"))

      el.append(node)
    })
  }
}

const renderShareButtons = (
  id,
  shareId,
  shareTitle,
  config = {
    facebook: {},
    twitter: {},
    pinterest: {},
    email: {},
  },
) => {
  if (!isBrowser) {
    return null
  }

  const {
    location: { origin, pathname },
  } = window
  const url = `${origin}${pathname}${shareId}${id}`

  const facebook = {
    url,
    ...config.facebook,
  }

  const twitter = {
    url,
    ...config.twitter,
  }

  const pinterest = {
    url,
    media: url,
    ...config.pinterest,
  }

  const email = {
    url,
    ...config.email,
  }

  return (
    <div className="ShareButtonsWrapper">
      <p className="ShareTitle">{shareTitle}</p>
      <div className="ShareButtons">
        <ShareButton type="facebook" {...facebook} />
        <ShareButton type="twitter" {...twitter} />
        <ShareButton type="pinterest" {...pinterest} />
        <ShareButton type="email" {...email} />
      </div>
    </div>
  )
}

// Custom hook to add and remove event listener for book consultation link
const useBookConsultationListener = (renderedData, handleLinkClick) => {
  useEffect(() => {
    const bookConsultElement = document.getElementsByClassName("book-consult");
    if (bookConsultElement) {
      for (var i = 0; i < bookConsultElement.length; i++) {
        bookConsultElement[i].addEventListener('click', handleLinkClick);
      }
    }

    return () => {
      if (bookConsultElement) {
        for (var i = 0; i < bookConsultElement.length; i++) {
          bookConsultElement[i].removeEventListener('click', handleLinkClick);
        }
      }
    };
  }, [renderedData, handleLinkClick]);
};

// Component to render individual blocks
const RenderBlock = ({ block, index, page, props, handleLinkClick }) => {
  const { t } = useTranslation();
  const { blockName, rendered } = block;

  // Replace placeholder with localized book consultation text
  const pageRenderedData = ["core/paragraph", "core/columns"].includes(blockName)
  ? rendered.replace(
      /\[BATHFITTER-BOOKCONSULTATION\]/g,
      `<span class="book-consult">${t("header.bookConsultation")}</span>`
    )
  : rendered;

  useBookConsultationListener(pageRenderedData, handleLinkClick);

  // Return null if blockName is not defined and rendered content is empty
  if (!blockName && !rendered.trim()) return null;

  // Render form block
  if (blockName === "bathfitter/form-block") {
    return (
      <div
        key={`${blockName}-${index}`}
        dangerouslySetInnerHTML={{ __html: rendered }}
      />
    );
  }

  // Render NinjaForm component
  if (blockName === "ninja-forms/form") {
    return <NinjaForm key={`${blockName}-${index}`} nf_assets={page.nf_assets[index]} />;
  }

  // Render text block with book consultation link
  if (["core/paragraph", "core/columns"].includes(blockName)) {
    return (
      <div key={`${blockName}-${index}`} className="TextBlock">
        {htmlParser(pageRenderedData, generateUuid(), props)}
      </div>
    );
  }

  // Render other blocks
  return (
    <div key={`${blockName}-${index}`}>
      {htmlParser(rendered, generateUuid(), props)}
    </div>
  );
};

// Function to render all blocks in a page or props
const renderBlocks = (page, props = {}, handleLinkClick = () => {}) => {
  if (page) {
    return page.block_data.map((block, i) => (
      <RenderBlock
        key={i}
        block={block}
        index={i}
        page={page}
        props={props}
        handleLinkClick={handleLinkClick}
      />
    ));
  }

  if (!page && props) {
    return props.post.block_data.map((block, i) => (
      <RenderBlock
        key={i}
        block={block}
        index={i}
        page={page}
        props={props}
        handleLinkClick={handleLinkClick}
      />
    ));
  }

  return null;
};

const isCurrentPage = pageSlugs => {
  const { pathname: pathName } = window.location
  const currentLocale = i18next.language === "qc" ? "fr" : "en"
  const slugRegex = new RegExp(pageSlugs[currentLocale], "g")
  const isCurrentPage = pathName.match(slugRegex)

  return isCurrentPage && isCurrentPage.length
}

const removeHtmlTags = string => string.replace(/(<([^>]+)>)/gi, "")

const saveUTMDataString = () => {
  if (isBrowser) {
    const { search } = window.location

    if (search) {
      const urlSearchParams = new URLSearchParams(search)

      if (urlSearchParams.get("utm_source")) {
        CookieService.set("utm_data", search, { path: "/" })
      }
    }
  }
}

const getUTMDataString = () => CookieService.get("utm_data") || ""

const getUrlWithoutTrailingSlash = url =>
  url.endsWith("/") ? url.slice(0, -1) : url

const getUrlWithTrailingSlash = url => (url.endsWith("/") ? url : `${url}/`)

const isFilePath = url => {
  const urlWithoutTrailingSlash = getUrlWithoutTrailingSlash(url)

  return !!urlWithoutTrailingSlash.match(/\.([^./?#]+)($|\?|#)/)
}

const findContentByClass = (item, searchClassName) => {
  const { children, className } = item

  if (className === searchClassName) {
    return item
  }

  if (children.length) {
    return [...children].reduce((acc, item) => {
      // return if already found
      if (acc) {
        return acc
      }

      const resultItem = findContentByClass(item, searchClassName)

      return resultItem || acc
    }, null)
  }

  return null
}

const formatStringToCamelCase = str => {
  const splitted = str.split("-")
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join("")
  )
}

const getStyleObjectFromString = (str = "") => {
  const style = {}
  str.split(";").forEach(el => {
    const [property, value] = el.split(":")
    if (!property) return

    const formattedProperty = formatStringToCamelCase(property.trim())
    style[formattedProperty] = value.trim()
  })

  return style
}

const loadLargeImage = event => {
  const image = event.currentTarget

  if (!image) return

  const imageSrc = image.getAttribute("src")
  const largeImageSrc = image.getAttribute("largeSrc")

  if (imageSrc !== largeImageSrc) {
    const largeImage = new Image()
    largeImage.src = largeImageSrc

    largeImage.onload = () => {
      // eslint-disable-next-line no-param-reassign
      image.src = largeImageSrc
      image.onload = () => {
        image.classList.add("ImageRevealed")
        image.classList.remove("ImagePreview")
      }
    }
  }
}

const getPreviewImageSrc = src => {
  const extensionIndex = src.lastIndexOf(".")

  if (extensionIndex === -1) return src

  const extension = src.substr(extensionIndex)
  const isSvg = extension === ".svg"

  if (isSvg) return src

  if (src.includes("-scaled.")) return src.replace("-scaled.", "-preview.")

  return `${src.substr(0, extensionIndex)}-preview${extension}`
}

// eslint-disable-next-line max-len
const getLogoSrc = (lngCode, base = process.env.GATSBY_FE_BF_URL) =>
  ["qc_en", "qc_fr"].includes(lngCode) ? `${base}${BMLogo}` : `${base}${BFLogo}`

const getGenericShareImage = (base = process.env.GATSBY_FE_BF_URL) =>
  `${base}${genericShareImage}`

function findPagePathById(pages, id) {
  for (let i = 0; i < pages.length; i++) {
    if (pages[i].id === id) {
      return `/${pages[i].lang}/${pages[i].slug}`
    }
  }
  return null
}

export {
  isBrowser,
  waitForInit,
  getPathname,
  loadAssets,
  renderBlocks,
  isCurrentPage,
  removeHtmlTags,
  saveUTMDataString,
  getUTMDataString,
  getUrlWithoutTrailingSlash,
  getUrlWithTrailingSlash,
  isFilePath,
  findContentByClass,
  renderShareButtons,
  getStyleObjectFromString,
  loadLargeImage,
  getPreviewImageSrc,
  getLogoSrc,
  getGenericShareImage,
  findPagePathById,
}
